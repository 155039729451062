.wrap-cms-page {
	.page-body.content-page-body {
		margin-top: 0;
		padding: 0;

		.nav-tabs {
			justify-content: center;
			border-bottom: 1px solid #dedede;

			.nav-item {
				color: #000;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-transform: uppercase;
				text-transform: uppercase;
				border-radius: 0px;
				display: block;
                text-align: center;
				border: none;
				padding: 15px 0;
				border-right: 1px solid #dedede;
				flex: 1 1 0%;
				&.active {
					border-bottom: none;
					background-color: transparent;
				}
				&:last-child {
					border-right: none;
				}
			}
		}
		.wrap-content {
			padding-left: 108px;
			padding-right: 68px;
			margin-top: 85px;
		}

		.wrap-media-list {
			display: flex;
			height: calc(100dvh - 130px - 361px);
			overflow-y: auto;
			gap: 18px;
			flex-wrap: wrap;
			justify-content: flex-start;
    		padding-right: 40px;
			
			&::-webkit-scrollbar {
				width: 5px;
			}

			.wrap-media-item {
				width: 32%;
				display: flex;
				flex-direction: column;
				height: 280px;

				.content-image {
					height: 225px;
					object-fit: cover;
				}

				.content-film {
					height: 225px;
					width: 100%;
				}

				.content-title-row {
					margin-top: 10px;
					height: 20px;
					display: flex;
					justify-content: space-between;

					.content-title {
						color: #000;
						font-family: Poppins;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						width: 65%;
						text-align: left;
					}

					.content-action {
						display: flex;
						width: 96px;
						justify-content: space-between;
						height: 20px;

						.img-fluid {
							width: 30px;
							height: 12px;
							cursor: pointer;
						}

						.form-check {
							margin-top: unset !important;
						}
						
						.action-edit {
							display: flex;
							justify-content: center;
							flex-direction: column;
							height: 100%;
						}
						.form-check-input {
							margin-top: unset;
						}
					}
				}
				.content-title-floorplans {
					display: flex;
					flex-direction: row;
					gap: 10px;
					height: 10px;
					font-family: Poppins;
					font-size: 14px;
					font-weight: 400;
					line-height: 21px;
					text-align: left;

					.content-title-floorplans-prefix {
						color: #CCCCCC;
					}
				}
			}
		}
		
		.add-new-content {
			margin-top: 15px;
			cursor: pointer;
			display: flex;
			gap: 15px;
			align-items: center;
			position: absolute;
			bottom: 53px;

			img {
				width: 27px;
				height: 27px;
			}

			span {
				color: #000;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}
		}
  }

	.form-switch {
		margin-top: -1px !important;
	}

	.form-switch .form-check-input {
		background-color: rgba(211, 208, 209, 0.35);
		width: 44px;
		height: 20px;	
		border: none !important;
		background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/></svg>");
		box-shadow: none;

		&:checked {
			background-color: #000 !important;
		}

		&:focus {
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='white'/></svg>");
		}
	}
}

.hidden {
	display: none;
}

.wrap-update-content-modal {
	.modal-content {
		max-height: 90vh;
	}
}

.wrap-update-content-modal,
.wrap-create-content-modal,
.wrap-delete-content-modal,
.wrap-replace-content-modal {
	background-color: black;
  input::placeholder {
    color: #d3d0d1;
  }
  
  .modal-dialog {
    --bs-modal-width: 100%;
    margin: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
    max-width: 100%;
    width: 100vw !important;
    height: 100vh !important;
    transition: none !important;

    .modal-content {
      width: 698px;
      position: relative;
      border-radius: 0;
      color: #000000;
      background-color: #ffffff;
      padding: 40px;
      gap: 50px;

      .select__menu {
        width: 200% !important;
      }
      
      .select__menu-portal {
        left: 40px !important;
        top: 380px !important;
      }


			.select__indicator-separator {
				display: none;
			}

			.select__control {
				border: none;
				border-bottom: 1px solid #000;;
				border-radius: 0;
				box-shadow: none;
				height: 23px;
				min-height: 23px;
				align-items: flex-start;
				background-color: unset;
			}

			.select__value-container {
				align-items: flex-start;
			}

			.select__placeholder,
			.select__single-value,
			.form-control,
			.form-control::placeholder {
				color: #000;
				font-family: Poppins;
				font-size: 14px;
				font-weight: 400;
				line-height: 1;
				text-align: left;
				opacity: 0.75;
			}
		
			.select__indicators {
				align-items: flex-start;
			}

			.select__indicator {
				padding: 0;
			}

			.select__value-container {
				padding: 0;
			}
    }

    .modal-header {
      padding: 0 !important;
      border: none;
    }

    .modal-footer {
      padding: 0 !important;
      border: none;
			justify-content: space-between;

			.save {
				color: #000;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
				padding: 12px 18px;
				border: 1px solid #000;
				text-align: center;
        cursor: pointer;
				margin: 0;
			}

			.delete {
				color: #CCC;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				cursor: pointer;
				margin: 0;
			}

			.submit {
				color: #000;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
				padding: 7px 18px;
				border: 1px solid #000;
				width: 100%;
				text-align: center;
				cursor: pointer;
				margin-top: 20px;
			}
    }

    button.close {
      display: none;
    }

    .modal-title {
      color: #000;
			font-family: Poppins;
			font-size: 30px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: uppercase;
    }
  }

  .wrap-modal-body {
    overflow: hidden scroll;
    color: #fff;
    position: relative;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    gap: 40px;
	  max-height: calc(100vh - 290px);
	  .mt-0 {
		  margin-top: 0 !important;
	  }
	  .mt-20 {
		  margin-top: 20px !important;
	  }

		.content {
			display: flex;
			flex-direction: column;
			position: relative;

			img, video {
				height: 330px;
				object-fit: cover;
				margin-top: 20px;
			}

			.upload-btn {
				position: absolute;
				width: 100%;
				height: 330px;
				top: 44px;
				background: rgba(0, 0, 0, 0.40);
				cursor: pointer;
				
				img {
					width: 50px;
					height: 50px;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);
				}
			}

			.upload-icon {
				margin: 0;
			}

			.error {
				color: #AE6537;
				font-family: 'Proxima Nova';
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 150%;
			}
		}

		.info {
			display: flex;
			gap: 20px;
			flex-direction: column;
			margin-bottom: 10px;

		}

		.title {
			color: #000;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 1;
			text-transform: uppercase;
			padding-bottom: 10px;
			border-bottom: 1px solid #CCC;
		}

    .wrap-form-group {
      display: flex;
      flex-direction: row;
      gap: 33px;
			padding-top: 13px;

      .error {
        color: #AE6537;
        font-family: 'Proxima Nova';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }

	.warning {
		color: orange;
		font-family: 'Proxima Nova';
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}
      .form-group {
          flex: 1 0 0;
      }
    }
    
    .wrap-button {
      margin-top: 50px;
      justify-content: flex-end !important;

      button {
        background: #000;
        color: #fff;
        padding: 16px;
        font-weight: normal;
        border: 1px solid #000;
        font-size: 14px;
        margin-left: 16px;
        margin-right: -16px;
        width: 92px;
        text-transform: uppercase;
        text-decoration-line: underline;
      }
    }

    .modal-form__title {
      font-size: 25px;
      line-height: 1.2em;
      text-transform: uppercase;
      font-weight: bold !important;

      &.underline-sm {
        &::before {
          width: 75px;
          background: #000;
          bottom: -9px;
          height: 1px;
        }
      }
    }
    .modal-form__sub-title {
      font-size: 18px;
    }

    .form-label {
			color: #000;
			text-align: center;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: 1;
			text-transform: uppercase;
			margin-bottom: 15px;
    }

    .form-control {
		border: none;
		border-bottom: 1px solid #000;
		border-radius: 0;
		box-shadow: none;
		padding: 0 0 13px 0;
		height: 28px;
		& * {
		background-color: white;
		width: 100px;
		}
    }

    .label {
      color: #414345;
      border-bottom: 1px solid #414345;
      font-size: 14px;
    }

    .type-value {
      font-size: 14px;
      margin-left: 10px;
      text-transform: uppercase;
      font-weight: 500;
    }

    .close-btn {
      position: fixed;
      top: 50px;
      right: 80px;
      width: 26px;
      height: 24px;
      z-index: 110;
    }

		.browser-file {
			color: #000;
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			text-transform: uppercase;
			border: 1px solid #000;
			padding: 7px 18px;
			text-align: center;
			cursor: pointer;
			margin-top: 20px;
		}

		.import {
			display: flex;
			flex-direction: column;
			gap: 15px;

			.import-title {
				color: rgba(45, 41, 39, 0.50);
				text-align: center;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				text-align: left;
			}

			.import-form {
				display: flex;
				justify-content: flex-end;

				input {
					width: 100%;
					background: #F5F7F7;
					outline: none;
					border: none;
					padding-left: 5px;

					&:focus-visible {
						box-shadow: none;
					}

					&::placeholder {
						color: #D3D0D1;
						font-family: Proxima Nova;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: 125%; /* 17.5px */
					}
				}

				.import-button {
					color: #000;
					font-family: Poppins;
					font-size: 14px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
					text-transform: uppercase;
					padding: 12px 18px;
					border: 1px solid #000;
					cursor: pointer;
				}
			}
		}

  }
}

.wrap-delete-content-modal {
  .modal-dialog {
    .modal-content {
      width: 521px !important;
			gap: 14px;
    }

		.wrap-modal-body {
			padding: 0 !important;
			
			.confirm {
				color: #000;
				font-family: 'Proxima Nova';
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: 125%; /* 17.5px */
			}
		}

		.modal-footer {
			margin-top: 26px;
			div {
				padding: 12px 18px;
				width: 100%;
				text-align: center;
				color: #E11736;
				font-family: Poppins;
				font-size: 14px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				text-transform: uppercase;
				border: 1px solid #E11736;
				cursor: pointer;
			}
		}
  }
}


.wrap-replace-content-modal {

  .modal-dialog {
    .modal-content {
		width: 630px !important;
		gap: 50px;
    }

		.wrap-modal-body {
			gap: 25px;
		}
  }
}

.wrap-create-content-modal {
  .modal-dialog {
    .modal-content {
      width: 630px !important;
			gap: 14px;
    }
	}
}

.custom-tab .nav-link.active {
	position: relative;
}
  
.custom-tab .nav-link.active::after {
	content: "";
	position: absolute;
	bottom: 8px; /* Adjusts the position of the dot */
	left: 50%;
	transform: translateX(-50%);
	width: 4px; /* Adjusts the dot size */
	height: 4px;
	border-radius: 50%;
	background-color: #000; /* Color of the dot */
}
  
